//封装防抖函数
let timer = null;
export function debounce(func, time) {
    let context = this;
	return function() {
		if (timer) clearTimeout(timer)
		timer = setTimeout(function(){
			func.apply(context)
		}, time)
	}()
}