<template>
    <div class="myBox">
        <div class="title">VIP套餐</div>
        <div class="listBox">
            <div :class="['list',currentIndex == index?'listActive':'listwhite']" v-for="(item,index) in listArr" :key="index" @click="itemClick(index,item.id)">
                <div class="left">
                    <div class="checked" v-if="currentIndex === index">
                        <div class="checkedChild"></div>
                    </div>
                    <div class="check"></div>
                </div>
                <div class="right">
                    <div class="rightItem">
                        <div class="num">
                            <div class="numTop">{{item.packageTime}}次</div>
                            <div class="numBot">{{item.packageDescription}}</div>
                        </div>
                        <div class="cenn">
                            自此次次数扣除后的{{item.packageHour}}小时内有效
                        </div> 
                        <div class="righ">
                            ￥{{item.paymentPrice}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="botBoxx">
            <div class="childList">
                <div class="money">
                    <p>应付金额：<span>￥{{price?price:'0'}}</span></p>
                </div>
                <div class="category">
                    <div class="weixin" @click="weiPrice" v-show="wxisShow">
                            <div class="code">
                                <img src="@/assets/img/weixinPay.png" alt="">
                            </div>
                            <div class="codePic">
                                <img src="@/assets/img/weixinpic.png" alt="">
                            </div>
                    </div>
                    <div class="zhifb" @click="zfbPrice" v-show="zfbisShow">
                        <div class="topImg">
                            <img src="@/assets/img/zhifubao.png" alt="">
                        </div>
                        <div class="btnImg">
                            <img src="@/assets/img/zfbBot.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rqCodd" v-show="codeFlag">
            <div class="close" @click="vipClose">X</div>
            <div class="wxTitle">{{$store.state.user.nickname}}购买{{itemObj.packageTime}}次套餐VIP</div>
            <div class="wxText">收款方：通辽市英国英语语言学校</div>
            <div class="wxText">剩余支付时间：{{timeMScoen}}</div>
            <div class="wxPrice">{{itemObj.paymentPrice}}<span>元</span></div>
            <div class="wxqrCode">
                <canvas ref="wxCode" id="weixinCode" class="weixin"></canvas>
            </div>
            <div class="wxText">超过支付时间会关闭该弹框!</div>
        </div>
    </div>
</template>

<script>
import myUrl from '@/common/request.js'
import { debounce } from '@/common/util.js'
import { videoPackageList,purchase,alipay,wxPay,getOrderStatus,payMod } from '@/common/public.js'
import QRCode from 'qrcode'
export default {
    data(){
        return{
            listArr:[],
            currentIndex:-1,
            rows:100,
            page:1,
            price:'',
            timer:null,//控制定时器的开启关闭
            videoPackageId:'',//下单id
            itemObj:{},//选中的套餐的参数
            mode:0,//0 表示微信 1表示 支付宝
            codeFlag:false,//控制微信二维码的展示
            numFlag:null,//如果该值为0轮询支付状态接口
            timeMScoen:'',//分钟秒数时间
            wxisShow:true,
            zfbisShow:true,
        }
    },
    created(){
        this.getVideoPackageList()
        this.getPayMod()
    },
    methods:{
        //获取支付方式
        getPayMod(){
            let data = {
                userId:this.$store.state.userid,
                token:this.$store.state.token
            };
            payMod(data).then(res=>{
                res.result.mapList.map(value=>{
                    if(value.code === 'PC_WX_SCAN'){
                        if(value.flag){
                            this.wxisShow = true;
                        }else{
                            this.wxisShow = false;
                        }
                    }
                    if(value.code === 'PC_AL_SCAN'){
                        if(value.flag){
                            this.zfbisShow = true;
                        }else{
                            this.zfbisShow = false;
                        }
                    }
                })
                
            })
        },
        //点击关闭生成微信二维码弹框
        vipClose(){
            this.codeFlag = false;
            clearInterval(this.timers)
            clearInterval(this.timer)
        },
        //改变下标下单接口
        itemClick(index,id){
            this.currentIndex = index;
            this.price = this.listArr[index].paymentPrice;
            this.videoPackageId = id;
            this.itemObj = this.listArr[index];
        },
        //生成订单编号
        getOrderNum(){
            let data = {
                appUserId:this.$store.state.userid,
                videoPackageId:this.videoPackageId
            };
            purchase(data).then(res=>{
                let msg = res.result.domain.msg;
                if(res.code == 200){
                    //判断支付方式 0 微信 1支付宝
                    if(this.mode == 0){
                        this.postWeiXin(msg)
                    }else{
                        this.postPay(msg)
                    }
                }
            })
        },
        //支付宝支付页面
        postPay(msg){
            let data = {
                subject:this.itemObj.packageName,
                outTradeNo:msg,
                totalAmount:this.itemObj.paymentPrice,
                returnUrl:myUrl.httpUrl+'#/payPage',
                body:this.itemObj.packageDescription
            };
            alipay(data).then(res=>{
                document.querySelector('body').innerHTML = res.result.alipay;
                document.forms[0].submit();
            })
        },
        //微信支付页面
        postWeiXin(msg){
            let data = {
                subject:this.itemObj.packageName,
                outTradeNo:msg,
                totalAmount:this.itemObj.paymentPrice,
                returnUrl:'',
                body:''
            };
            wxPay(data).then(res=>{
                if(res.code == 200){
                    this.codeFlag = true;
                    let myCode = this.$refs.wxCode;
                    let url = res.result.codeUrl;
                    QRCode.toCanvas(myCode,url, { margin: 1 ,width:126,height:126},  (error)=> {
                        if (error) console.error(error)
                        this.$message({
                            showClose: true,
                            message: '正在支付中请稍等',
                            type: 'warning'
                        }); 
                        this.tabqrCode()
                        this.timer = setInterval(()=>{
                            this.getSomeRequest(msg)
                        },2000)
                    })
                }
            })
        },
        //倒计时三分钟切换二维码
        tabqrCode(){
        let time = 600;
        this.timers = setInterval(()=>{
            if(time>=0){
                let m = Math.floor(time/60)>10?Math.floor(time/60):'0'+Math.floor(time/60);  
                let s = Math.floor(time%60)>10?Math.floor(time%60):'0'+Math.floor(time%60);
                --time
                this.timeMScoen = m+':'+s+'s';
            }else{
                clearInterval(this.timers)
                clearInterval(this.timer)
                this.codeFlag = false;
                this.$message({
                    showClose: true,
                    message: '超过支付时间请重新下单',
                    type: 'warning'
                });
            } 
        },1000)
        },
        //轮询调用支付状态接口
        getSomeRequest(msg){
            let data = {
                orderNo:msg
            };
            getOrderStatus(data).then(res=>{
                if(res.result.orderStatus === '1'){
                    clearInterval(this.timer)
                    clearInterval(this.timers)
                    this.$router.push({
                        path:'/payPage',
                        query:{
                            flag:true,
                            isBuyFlag:'payTrue'
                        }
                    })
                }else if(res.result.orderStatus === '2'){
                    clearInterval(this.timer)
                    clearInterval(this.timers)
                    this.$router.push({
                        path:'/payPage',
                        query:{
                            flag:false,
                            isBuyFlag:'payTrue'
                        }
                    })
                }else{
                    this.numFlag = '0';
                }
            })
        },
        //点击选择微信支付
        weiPrice(){
            if(this.currentIndex<0){
                 this.$message({
                    showClose: true,
                    message: '请选择购买套餐',
                    type: 'error'
                });
            }else{
                this.mode = 0;
                debounce(this.getOrderNum,1000)
            }
        },
        //点击选择支付宝支付
        zfbPrice(){
            if(this.currentIndex<0){
                this.$message({
                    showClose: true,
                    message: '请选择购买套餐',
                    type: 'error'
                });
            }else{
                this.mode = 1;
                debounce(this.getOrderNum,1000)
            }
        },
        //获取列表
        getVideoPackageList(){
            let data = {
                page:this.page,
                rows:this.rows
            };
            videoPackageList(data).then(res=>{
                this.listArr = res.result.mapList;
            })
        }
    }
}
</script>

<style scoped lang="less">
.myBox{
    width: 1200px;
    // height: 1016px;
    padding-bottom: 25px;
    background: #fff;
    .title{
        width: 100%;
        padding-left: 37px;
        font-size: 20px;
        font-weight: bold;
        line-height: 67px;
        color: #333333;
        border: 1px solid #EEEEEE;
    }
    .listBox{
        width: 100%;
        padding: 0 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .listwhite{
            border: 1px solid #eeeeee;
            background: #fff;
        }
        .listActive{
            background: rgba(9, 118, 56, 0.06);
            border: 1px solid #097638;
        }
        .list,.listActive{
            width: 100%;
            height: 82px;
            margin-top: 30px;
            padding: 0 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left{
                width: 16px;
                height: 16px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                position: relative;
                .check{
                    width: 16px;
                    height: 16px;
                    background: #fff;
                    border: 1px solid #eee;
                    border-radius: 50%;
                }
                .checked{
                    width: 14px;
                    height: 14px;
                    border: 1px solid #097638;
                    border-radius: 50%;
                    padding: 2px;
                    .checkedChild{
                        width: 8px;
                        height: 8px;
                        background: #097638;
                        border-radius: 50%;
                    }
                }
            }
            .right{
                width: 1055px;
                .rightItem{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    .num{
                        width: 20%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .numTop{
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 16px;
                            color: #333333;
                            margin-bottom: 6px;
                        }
                        .numBot{
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;
                            color: #666666;
                        }
                    }
                    .cenn{
                        width: 60%;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        color: #666666;
                    }
                    .righ{
                        text-align: right;
                        width: 20%;
                        font-size: 28px;
                        font-weight: 400;
                        line-height: 16px;
                        color: #FF8000;
                    }
                }
            }
        }
    }
    .botBoxx{
        padding: 0 25px;
        margin-top: 30px;
        .childList{
            border: 1px solid #eee;
            padding-bottom: 80px;
            .money{
                width: 100%;
                padding: 60px 0 70px 0;
                p{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    color: #666666;
                    span{
                        font-size: 28px;
                        font-weight: 400;
                        line-height: 16px;
                        color: #FF8000;
                        margin-left: 15px;
                    }
                }
            }
            .category{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                .weixin{
                    display: flex;
                    flex-direction:column;
                    justify-content: space-between;
                    align-items: center;
                    width: 172px;
                    .code{
                        width: 172px;
                        height: 172px;
                        background: #FFFFFF;
                        margin-bottom: 14px;
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        img{
                            width: 170px;
                            height: 170px;
                        }
                    }
                    .codePic{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around;
                        align-items: center;
                        cursor: pointer;
                        img{
                            width: 110px;
                            height: 28px;
                        }
                    }
                }
                .zhifb{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    width: 167px;
                    .topImg{
                        width: 167px;
                        height: 167px;
                        background: blue;
                        margin-bottom: 14px;
                        img{
                            width: 167px;
                            height: 167px;
                            background: blue;
                            cursor: pointer;
                        }
                    }
                    .btnImg{
                        width: 170px;
                        height: 40px;
                        img{
                            width: 170px;
                            height: 40px;
                            cursor: pointer;
                        }
                        
                    }
                }
            }
        }
    }
    .rqCodd{
        width: 439px;
        height: 439px;
        border: 1px solid #ccc;
        position: fixed;
        background: #fff;
        padding: 0 20px;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        .close{
            width: 100%;
            height: 48px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            font-size: 20px;
            cursor: pointer;
        }
        .wxTitle{
            text-align: center;
            width: 100%;
            margin-top: 18px;
            margin-bottom: 7px;
            font-size: 16px;
            font-weight: bold;
            line-height: 16px;
            color: #000000;
        }
        .wxText{
            text-align: center;
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            color: #000000;
            margin-bottom: 20px;
        }
        .wxPrice{
            text-align: center;
            width: 100%;
            font-size: 24px;
            font-weight: bold;
            line-height: 16px;
            color: #FF6600;
            span{
                font-size: 14px;
                line-height: 16px;
            }
        }
        .wxqrCode{
            margin-top: 28px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 172px;
            height: 172px;
            border: 1px solid #4BB414;
            margin-bottom: 10px;
        }
    }
}
</style>