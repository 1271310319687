<template>
    <div>
        <commonHeader :current-index="3"></commonHeader>
        <div class="contation">
            <paymentVip></paymentVip>
        </div>
        <commonFooter></commonFooter>
    </div>
</template>

<script>
import commonHeader from '@/components/commonHeader'
import paymentVip from '@/components/payment/paymentVip' 
import commonFooter from '@/components/commonFooter'
export default {
    components:{ commonHeader,paymentVip,commonFooter},
    data(){
        return{
            
        }
    },
   
}
</script>

<style scoped lang="less">
.contation{
    width: 100%;
    background: #f7f7f7;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>